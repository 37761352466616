import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";
import AppBar from "./../../components/DefaultAppBar";
import { withStyles } from "@material-ui/core/styles";
import { compose, mapProps } from "recompose";
import ProfileHeader from "./ProfileHeader";
import { withCurrentUser, withCurrentEstate } from "../../functions";
import { FolderList, IOSwitch } from "../../components/Common";
import { updateAccount, getCurrentAccount } from "../../modules/accounts";
import { ListItem, FormControl, Typography } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { withFormik } from "formik";
import { withCurrentAccount } from "../../modules/accounts/accountHocs";
import DocumentLayout from "../../components/Layouts/DocumentLayout";

export const route = "/profile/privacy";

const notificationSettingsContent = [
  {
    name: {
      id: "settings.public.tickets.new",
      defaultMessage: "Eine neue öffentliche Mitteilung",
    },
    type: "onNewPublicTicket",
  },
  {
    name: {
      id: "settings.public.tickets.answer",
      defaultMessage: "Eine Antwort auf eine öffentliche Mitteilung",
    },
    type: "onPublicTicketAnswer",
  },
  {
    name: {
      id: "settings.public.tickets.statusChange",
      defaultMessage: "Ein neuer Status einer Mitteilung",
    },
    type: "onPublicTicketStatusChange",
  },
  {
    name: {
      id: "settings.documents.new",
      defaultMessage: "Ein neues Dokument",
    },
    type: "onNewDocument",
  },
];

class SettingsList extends React.PureComponent {
  render() {
    const {
      intl: { formatMessage },
      classes,
    } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FolderList
          // subheader="Angaben zu ihren Wohneinheiten"
          data={this.props.data}
        >
          {(document, index) => {
            return (
              <ListItem
                key={`${index}-${document.id}`}
                className={classes.container}
              >
                <Typography variant="subtitle1" inline>
                  {formatMessage(document.name)}
                </Typography>
                <FormControl style={{ marginBottom: "12px" }}>
                  <IOSwitch
                    name={document.type}
                    value={!!this.props.values[document.type]}
                    checked={!!this.props.values[document.type]}
                    onChange={(event, value) => {
                      this.props.onChange(document.type, value);
                      this.props.setFieldValue(document.type, value);
                    }}
                  />
                </FormControl>
              </ListItem>
            );
          }}
        </FolderList>
      </form>
    );
  }
}

// Wrap our form with the using withFormik HoC
const formControl = {
  // Transform outer props into form values
  // mapPropsToValues: (props) => {
  //   const settings = { ...props.defaultSettings };
  //   // console.log("settings.onNewDocument => ", settings.onNewDocument);
  //   // console.log("settings.onNewPosting => ", settings.onNewPosting);
  //   // console.log("settings.onNewPrivateTicket => ", settings.onNewPrivateTicket);
  //   return ({
  //     onNewDocument: settings.onNewDocument,
  //     onNewPosting: settings.onNewPosting,
  //     onNewPrivateTicket: settings.onNewPrivateTicket,
  //     onNewPublicTicket: settings.onNewPublicTicket,
  //     onPrivateTicketAnswer: settings.onPrivateTicketAnswer,
  //     onPublicTicketAnswer: settings.onPublicTicketAnswer,
  //     onPublicTicketStatusChange: settings.onPublicTicketStatusChange,
  //   });
  // }
};

const stylesList = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(),
    paddingBottom: 0,
  },
});

const IntelSettings = compose(
  injectIntl,
  withStyles(stylesList),
  // TODO: Remove formik
  withFormik(formControl),
  mapProps((props) => ({
    ...props,
    values: { ...props.defaultSettings },
  })),
)(SettingsList);

/**
 *
 */
class ProfileContainer extends Component {
  static route = route;

  state = {
    account: {},
    notificationSettings: {},
  };

  componentDidMount() {
    this.fetch();
  }

  UNSAFE_componentWillReceiveProps() {
    this.fetch();
  }

  fetch = () => {
    const accountId = this.props.currentAccount.accountId;
    getCurrentAccount(accountId).then(({ data }) => {
      this.setState({
        account: data,
        notificationSettings: (data || {}).notificationSettings,
      });
    });
  };

  handleChange = (field, value) => {
    const accountId = this.props.currentAccount.accountId;
    const notificationSettings = this.state.notificationSettings || {};

    updateAccount(accountId, { ...notificationSettings, [field]: value }).then(
      (data) =>
        this.setState({
          notificationSettings: data,
        }),
    );
  };

  render() {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <div>
        <AppBar position="static">
          <TicketListToolBar noTicketFilter />
        </AppBar>

        <ProfileHeader estate={this.props.currentEstate} />

        <DocumentLayout>
          <Typography variant="h6" className={classes.content}>
            {formatMessage({ id: "profile.privacy.title.short" })}
          </Typography>

          <Typography className={classes.content}>
            {formatMessage({ id: "profile.privacy.title" })}
          </Typography>

          <Typography className={classes.content}>
            {formatMessage({ id: "profile.privacy.settings.desc" })}
          </Typography>

          {/* <div dangerouslySetInnerHTML={{ __html: title }} /> */}

          <IntelSettings
            data={notificationSettingsContent}
            onChange={this.handleChange}
            defaultSettings={this.state.notificationSettings}
          />
        </DocumentLayout>
      </div>
    );
  }
}

const styles = (theme) => ({
  center: {
    display: "flex",
    margin: "50% auto",
    fontSize: "10rem",
  },
  button: {
    width: "100%",
  },
  // title: {
  //   marginTop: 12,
  //   marginLeft: theme.spacing(2),
  //   color: "rgba(0, 0, 0, 0.54)",
  // },
  content: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.54)",
  },
});

export default compose(
  withRouter,
  injectIntl,
  withCurrentUser(),
  withCurrentAccount(),
  withCurrentEstate(),
  withStyles(styles),
)(ProfileContainer);
