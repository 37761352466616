import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MainNavigation from "./Navigation/MainNavigation";
import SignInContainer from "../containers/SignInContainer/SignInContainer";
import Container from "./Common/Container";

const ProtectedRoute = ({ component: Component, sessionToken, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return sessionToken ? (
        <React.Fragment>
          {/* content */}
          <Container>
            <Component {...props} />
          </Container>

          {/* main navigation */}
          <MainNavigation {...props} />
        </React.Fragment>
      ) : (
        <Redirect
          to={{
            pathname: SignInContainer.route,
            from: props.location,
          }}
        />
      );
    }}
  />
);

export default withRouter(
  connect(({ sessionToken }) => ({ sessionToken }))(ProtectedRoute),
);
