import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import AppBar from "./../../components/DefaultAppBar";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { logoutUser } from "./../../modules/user/userActions";
import UpdatePasswordContainer from "./../../containers/UpdatePasswordContainer/UpdatePasswordContainer";
import ProfileHeader from "./ProfileHeader";
import SimpleProfile from "./SimpleProfile";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import bg from "./../assets/hausify-logo-app.jpg";
import { withCurrentUser, withCurrentEstate } from "../../functions";
import { injectIntl } from "react-intl";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";

export const route = "/profile";

const profileContent = [
  {
    primary: "Kontakt zu Ihrer Verwaltung",
    i18Id: "profile.link.property_management",
    to: "/property-management",
    // action: (props) => props.history.push("/property-management")
  },

  {
    primary: "Benachrichtigungseinstellungen",
    i18Id: "profile.link.notification_settings",
    // to: `${route}/privacy`
    action: (props) => props.history.push(`${route}/privacy`),
  },
  {
    primary: "Nutzungsbedingungen",
    i18Id: "profile.link.terms_of_service",
    // to: `${route}/terms`
    action: (props) => props.history.push(`${route}/terms`),
  },

  {
    primary: "Datenschutz",
    i18Id: "profile.link.data_privacy",
    action: (props) => props.history.push(`${route}/data-privacy`),
  },
  {
    primary: "AGB",
    i18Id: "profile.link.tandc",
    // to: `${route}/tandc`
    action: (props) => props.history.push(`${route}/tandc`),
  },
  {
    primary: "Impressum",
    i18Id: "profile.link.imprint",
    // to: `${route}/imprint`,
    action: (props) => props.history.push(`${route}/imprint`),
  },
  {
    primary: "Passwort ändern",
    i18Id: "profile.link.change_password",
    action: (props) => props.history.push(UpdatePasswordContainer.route),
  },
  {
    primary: "Logout",
    i18Id: "profile.link.logout",
    action: (props) => props.dispatch(logoutUser()),
  },
];

class ProfileContainer extends Component {
  static route = route;

  render() {
    const {
      classes,
      currentEstate,
      intl: { formatMessage },
    } = this.props;
    return (
      <div>
        <AppBar position="static">
          <TicketListToolBar noTicketFilter />
        </AppBar>

        <div className={classes.profile}>
          <ProfileHeader estate={currentEstate} />
        </div>

        <div className={classes.profile}>
          <SimpleProfile currentUser={this.props.currentUser} />
        </div>

        <List>
          {profileContent.map((item) => {
            const listItemOptions = {
              key: `${item.to}-${item.primary}`,
            };

            if (item.to) {
              listItemOptions.to = item.to;
              listItemOptions.component = Link;
            } else {
              listItemOptions.button = true;
              if (item.action) {
                listItemOptions.onClick = (e) => item.action(this.props);
              }
            }

            return (
              <ListItem {...listItemOptions} divider>
                <ListItemText primary={formatMessage({ id: item.i18Id })} />
                <ListItemSecondaryAction>
                  <ListItemIcon style={{ minWidth: 0 }}>
                    <KeyboardArrowRight />
                  </ListItemIcon>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <div className={classes.logo} align="center">
          <img src={bg} alt="hausify logo" />
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  profile: {
    marginBottom: theme.spacing(2),
  },

  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    width: "75%",
  },
});

export default compose(
  withRouter,
  injectIntl,
  withCurrentUser(),
  withCurrentEstate(),
  withStyles(styles),
)(ProfileContainer);
