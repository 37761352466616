import customThemeForId7 from "./customThemeForId7";
import customThemeForId151 from "./customThemeForId151"; // HV Prod (Demo)
import customThemeForId941 from "./customThemeForId941"; // PeBo
import customThemeForId1060 from "./customThemeForId1060"; // FEWA
import customThemeForId923 from "./customThemeForId923"; // FeSt Grundstücksverwaltung
import customThemeForId753 from "./customThemeForId753"; // FeSt Grundstücksverwaltung
import customThemeForId1254 from "./customThemeForId1254"; // VID
import customThemeForId1462 from "./customThemeForId1462"; // Bootkitas
import customThemeForId1383 from "./customThemeForId1383"; // MYWAY
import customThemeForId1534 from "./customThemeForId1534"; // GEWOBAU
import customThemeForId1535 from "./customThemeForId1535"; // Feriendorf
import customThemeForId1493 from "./customThemeForId1493"; // RMW Test
import customThemeForId1528 from "./customThemeForId1528"; // RMS Kastellaun
import customThemeForId1540 from "./customThemeForId1540"; // Schaumburger
import customThemeForId1559 from "./customThemeForId1559"; // HS Hausmeister
import hausifyTheme from "./hausifyTheme";

export default function getThemeById(id) {
  switch (id) {
    case 7:
      return customThemeForId7;

    case 151: // HV Prod (Demo)
      return customThemeForId151;

    case 923: // FeSt Grundstücksverwaltung
      return customThemeForId923;

    case 941: // PeBo
      return customThemeForId941;

    case 1060: // PeBo
      return customThemeForId1060;

    case 1254: // VID
      return customThemeForId1254;

    case 753: // Flat-mate
      return customThemeForId753;

    case 57: // 57 existiert nicht auf PROD, nur STAGE: stageverwaltung1@hausify.de
      return customThemeForId151;

    case 1462: // bootkitas
      return customThemeForId1462;
    case 1383: // MYWAY
      return customThemeForId1383;

    case 1534: // Feriendorf
      return customThemeForId1534;

    case 1535: // GEWOBAU
      return customThemeForId1535;

    case 1493: // RMW Test
      return customThemeForId1493;

    case 1528: // RMW Kastellaun
      return customThemeForId1528;

    case 1540: // Schaumburger
      return customThemeForId1540;

    case 1559: // HS Hausmeisterserivce UG
      return customThemeForId1559;

    default:
      return hausifyTheme;
  }
}
