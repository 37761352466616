import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";
import AppBar from "./../../components/DefaultAppBar";
import { compose } from "recompose";
import ProfileHeader from "./ProfileHeader";
import {
  // withCurrentUser,
  withCurrentEstate,
} from "../../functions";

export const route = "/profile/account";

class AccountContainer extends Component {
  static route = route;
  render() {
    return (
      <div>
        <AppBar position="static">
          <TicketListToolBar noTicketFilter />
        </AppBar>

        <ProfileHeader estate={this.props.currentEstate} />
      </div>
    );
  }
}

export default compose(withRouter, withCurrentEstate())(AccountContainer);
