import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";
import AppBar from "./../../components/DefaultAppBar";
import ProfileHeader from "./ProfileHeader";
import { withCurrentEstate } from "../../functions";
import { injectIntl } from "react-intl";

export const route = "/profile/contact";

// Kontakt zu hausify

// const contact = ' <head> <style> body {   font-family: "Open Sans", arial,helvetica,sans-serif; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 21.0px;} </style> </head><p style="text-align: center;"><strong>Kontakt zu hausify</strong></p> <ul> <li><strong>per email zu den Entwicklern</strong><br />Schreiben Sie uns unter: <a href="mailto:hello@hausify.de">hello@hausify.de<br /><br /></a></li> <li><strong>oder nutzen Sie unser Formular</strong><br />Link zu unserer Webpr&auml;senz: <a href="https://www.hausify.de/impressum">https://www.hausify.de/impressum<br /><br /></a></li> <li><strong>per Telefon&nbsp;</strong><br />erreichen Sie uns zu Mo-Fr 9-17 Uhr unter 030&nbsp;4036 74000</li> </ul></body>'

class AccountContainer extends Component {
  static route = route;
  render() {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;
    return (
      <div>
        <AppBar position="static">
          <TicketListToolBar noTicketFilter />
        </AppBar>

        <ProfileHeader estate={this.props.currentEstate} />

        <Typography variant="h6" align="center" className={classes.title}>
          {formatMessage({ id: "profile.contact.title.subtitle" })}
        </Typography>

        <ul>
          <li>
            <Typography variant="subtitle1" className={classes.subtitle1}>
              {formatMessage({ id: "profile.contact.developer.label" })}
            </Typography>

            <Typography component="p" className={classes.content}>
              {`${formatMessage({
                id: "profile.contact.developer.content",
              })}: `}
              <a href="mailto:hello@hausify.de">hello@hausify.de</a>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" className={classes.subtitle1}>
              {formatMessage({ id: "profile.contact.hausify.label" })}
            </Typography>

            <Typography component="p" className={classes.content}>
              {`${formatMessage({ id: "profile.contact.hausify.content" })}: `}
              <a href="https://www.hausify.de/impressum">
                https://www.hausify.de/impressum
              </a>
              {/* Link zu unserer Webpr&auml;senz:  */}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" className={classes.subtitle1}>
              {formatMessage({ id: "profile.contact.phone.label" })}
            </Typography>

            <Typography component="p" className={classes.content}>
              {formatMessage({ id: "profile.contact.phone.content" })}
            </Typography>
          </li>
        </ul>
      </div>
    );
  }
}

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  subtitle1: {
    marginTop: theme.spacing(2.5),
    fontWeight: "bold",
    // listStyleType: "disc"
  },
  content: {
    marginTop: theme.spacing(0.4),
  },
  center: {
    display: "flex",
    margin: "50% auto",
    fontSize: "10rem",
  },
  button: {
    width: "100%",
  },
});

export default compose(
  withRouter,
  injectIntl,
  withCurrentEstate(),
  withStyles(styles),
)(AccountContainer);
