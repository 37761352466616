import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import AppBar from "../../components/DefaultAppBar";
import { Search, DocumentsExpansionPanel } from "../../components/Documents";
import { ViewTitle } from "../../components/Common";
import { DocumentsListContainer } from "../../components/Documents/ExpansionPanels";

import TicketListToolBar from "../TicketListContainer/TicketListToolBar";

import {
  fetchDocumentCategoriesThunk,
  fetchDocumentsThunk,
} from "../../modules/documents/documentOperations";
import { documentSelectors } from "../../modules/documents/documentsSlice";
import { documentCategorySelectors } from "../../modules/documents/documentCategoriesSlice";

export const route = "/documents";

const styles = (theme) => ({
  center: {
    display: "flex",
    margin: "50% auto",
    fontSize: "10rem",
  },
});

class DocumentContainer extends Component {
  static route = route;

  state = {
    searchActive: false,
    searchResult: [],
  };

  async componentDidMount() {
    this.props.fetchDocumentCategoriesThunk();
    this.props.fetchDocumentsThunk();
  }

  onChangeSearch = ({ data, active }) => {
    this.setState({
      searchResult: data,
      searchActive: active,
    });
  };

  contentFilter = (array = []) => {
    const content = array.reduce(
      (acc, document) => [
        ...acc,
        ...document.attachments.map((attachment) => ({
          ...attachment,
          title: document.title,
        })),
      ],
      [],
    );

    return content.map((document) => ({
      ...document,
      subject: document.title,
    }));
  };

  render() {
    const { classes, documents, documentCategories } = this.props;
    const filteredSearchResult = this.contentFilter(documents);

    return (
      <div className={classes.root}>
        <AppBar>
          <TicketListToolBar noTicketFilter />
        </AppBar>

        <ViewTitle title="" />

        <Search onChange={this.onChangeSearch} />

        <div style={{ marginBottom: "12px" }} />

        {this.state.searchActive ? (
          <DocumentsListContainer data={filteredSearchResult} />
        ) : (
          <DocumentsExpansionPanel
            primary={documentCategories}
            secondary={documents}
            getSecondaryContent={this.contentFilter}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  documents: documentSelectors.selectAll(state),
  documentCategories: documentCategorySelectors.selectAll(state),
});

const mapDispatchToProps = {
  fetchDocumentsThunk,
  fetchDocumentCategoriesThunk,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles, { withTheme: true }),
)(DocumentContainer);
