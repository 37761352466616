import React from "react";
import Typography from "@material-ui/core/Typography";
import { branch, renderComponent, compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withCurrentEstate } from "./../../functions";
import { hasEntries } from "./../../functions";
import { fullAddress } from "../../functions/users";
import { injectIntl } from "react-intl";
import { getImageSRC } from "../../functions/assets";

// HELPER //
const getCustomTextFromArray = (estateArray) => {
  const freetextArray = estateArray.filter(([field, val]) =>
    field.includes("freetext"),
  );
  return freetextArray;
};

const NoPropertyAvailable = (props) => {
  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom>
        Estate
      </Typography>
      <div>No estate available</div>
    </React.Fragment>
  );
};

const isEstateFieldEmpty = ({ estate }) => estate == null;

const noPropertyAvailable = branch(
  isEstateFieldEmpty,
  renderComponent(NoPropertyAvailable),
);

const styles = (theme) => ({
  root: {
    textAlign: "center",
  },
  freetext: {
    marginBottom: theme.spacing(),
  },

  image: {
    display: "block",
    margin: `${theme.spacing(2)}px auto ${theme.spacing(2)}px auto`,
    width: "50%",
  },

  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    width: "70%",
  },
});

const filterFreetext = ([field, freetext]) => freetext;

const renderFreetexts =
  ({ classes }) =>
  ([field, freetext], index) => {
    return (
      <div
        key={`${field}-${index}`}
        className={classes.freetext}
        dangerouslySetInnerHTML={{ __html: freetext }}
      />
    );
  };

class PropertyManagementProfile extends React.PureComponent {
  render() {
    const {
        estate,
        classes,
        currentEstate,
        intl: { formatMessage },
      } = this.props,
      { address, logo } = estate,
      freeTextArray = getCustomTextFromArray(
        Object.entries(estate || Object.create(null)),
      );

    return (
      <div className={classes.root}>
        {logo && (
          <div className={classes.logo}>
            <img
              className={classes.image}
              src={getImageSRC(logo.filename)}
              alt={logo.name}
            />
          </div>
        )}

        {estate && <Typography variant="h5">{estate.name}</Typography>}
        {estate && <Typography variant="h6">{estate.infoText}</Typography>}

        {address && (
          <Typography variant="subtitle1" style={{ marginBottom: "20px" }}>
            {fullAddress(address)}
          </Typography>
        )}

        {address && estate && (
          <Typography variant="h6"> {estate.emailAddress}</Typography>
        )}

        <Typography variant="h6" gutterBottom>
          {formatMessage({ id: "profile.property_management.headline" })}
        </Typography>

        {hasEntries(freeTextArray) &&
          freeTextArray
            .filter(filterFreetext)
            .map(renderFreetexts({ classes }))}

        <Typography variant="h6" gutterBottom>
          {formatMessage({
            id: "profile.property_management.info_your_estate",
          })}
        </Typography>

        {currentEstate && currentEstate.infoText && (
          <Typography variant="subtitle1">{currentEstate.infoText}</Typography>
        )}
      </div>
    );
  }
}

export default compose(
  noPropertyAvailable,
  injectIntl,
  withStyles(styles),
  withCurrentEstate(),
)(PropertyManagementProfile);
