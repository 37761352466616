import React from "react";
import UpdatePasswordForm from "./UpdatePasswordForm";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";
import AppBar from "../../components/DefaultAppBar";

const UpdatePasswordContainer = () => (
  <div>
    <AppBar position="static">
      <TicketListToolBar noTicketFilter />
    </AppBar>
    <UpdatePasswordForm />
  </div>
);

UpdatePasswordContainer.route = "/update-password";

export default UpdatePasswordContainer;
