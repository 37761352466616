import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { injectIntl } from "react-intl";
import ProfileHeader from "./ProfileHeader";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";
import AppBar from "./../../components/DefaultAppBar";
import {
  withCurrentUser,
  withCurrentEstate,
  withEstates,
} from "../../functions";
import { FolderList } from "../../components/Common";
import { fullAddress } from "../../functions/users";

/**
 *
 */
export const route = "/profile/my-data";

/**
 *
 * @param {*} data
 */
const getEstatesContent = (data) => {
  return (data || []).map((document) => ({
    name: document.name,
    address: fullAddress(document.address),
    propertyManagementId: document.propertyManagementId,
  }));
};

/**
 *
 */
const AllMyEstatesInformation = injectIntl((props) => (
  <FolderList
    // subheader="Angaben zu ihren Wohneinheiten"
    data={getEstatesContent(props.data)}
  >
    {(document, index) => (
      <ListItem key={`${index}-${document.id}`}>
        <ListItemText
          primary={document.name}
          // secondary={`${document.address} - ${props.intl.formatMessage({ id: "profile.my_data.estate_info.unit" })}: ${document.propertyManagementId}`}
          // secondary={`${document.address}  ${props.intl.formatMessage()}: `}
        />
      </ListItem>
    )}
  </FolderList>
));

/**
 *
 */
class MyDataContainer extends Component {
  static route = route;

  render() {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <TicketListToolBar noTicketFilter />
        </AppBar>

        <ProfileHeader estate={this.props.currentEstate} />

        <Typography variant="h6" className={classes.title} align="center">
          {formatMessage({ id: "profile.my_data.title.subtitle" })}
        </Typography>

        <ul>
          <li>
            <Typography variant="subtitle1" className={classes.title}>
              {formatMessage({ id: "profile.my_data.current_estates" })}
            </Typography>

            <AllMyEstatesInformation data={[this.props.currentEstate]} />
          </li>
          <li>
            <Typography variant="subtitle1" className={classes.title}>
              {formatMessage({ id: "profile.my_data.all_available_estates" })}
            </Typography>

            <AllMyEstatesInformation data={this.props.estates} />
          </li>
        </ul>
      </div>
    );
  }
}

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  center: {
    display: "flex",
    margin: "50% auto",
    fontSize: "10rem",
  },
  button: {
    width: "100%",
  },
});

export default compose(
  withRouter,
  injectIntl,
  withCurrentUser(),
  withCurrentEstate(),
  withEstates(),
  withStyles(styles),
)(MyDataContainer);
