import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import AppBar from "./../../components/DefaultAppBar";
import PropertyManagementProfile from "./PropertyManagementProfile";
import TicketListToolBar from "../TicketListContainer/TicketListToolBar";
import DocumentLayout from "./../../components/Layouts/DocumentLayout";

export const route = "/property-management";

class PropertyManagementContainer extends Component {
  static route = route;

  render() {
    const { propertyManagement } = this.props;
    return (
      <div>
        <AppBar position="static">
          <TicketListToolBar noTicketFilter />
        </AppBar>
        <DocumentLayout>
          <PropertyManagementProfile estate={propertyManagement} />
        </DocumentLayout>
      </div>
    );
  }
}

const mapStateToProps = ({ propertyManagement }) => {
  return {
    propertyManagement,
  };
};

export default compose(connect(mapStateToProps))(PropertyManagementContainer);
