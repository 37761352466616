import React from "react";
import { withStyles } from "@material-ui/core/styles";

/**
 * DocumentLayout
 * A layout for documents
 */
function DocumentLayout({ children, classes }) {
  return <div className={classes.root}>{children}</div>;
}

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
});

export default withStyles(styles)(DocumentLayout);
